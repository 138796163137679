.overlay-class {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1100; 
}

.modal-content {
  background: #1e1e1e;
  padding: 20px;
  max-width: 600px;
  width: 90%;
  border-radius: 8px;
  outline: none;
  position: relative;
  z-index: 1100; 
}

.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #333;
  padding-bottom: 10px;
}

.modal-header img {
  border-radius: 50%;
}

.modal-title {
  display: flex;
  flex-direction: column;
}

.modal-title h2 {
  margin: 0;
  font-size: 1.5rem;
  color: #fff;
}

.modal-title p {
  margin: 0;
  color: #bbb;
}

.close-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #fff;
  cursor: pointer;
  position: relative; /* Retirer la position absolue */
}

.modal-body {
  max-height: 60vh;
  overflow-y: auto;
}

.audio-section {
  margin: 15px;
}

.audio-section h3 {
  font-size: 1.5rem;
  color: #fff;
  margin-bottom: 10px;
  padding-top: 15px;
}

.audio-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
}

.audio-item {
  display: flex;
  flex-direction: column;
}

.audio-item p {
  color: white;
  margin-bottom: 10px;
  margin-top: 10px;
}

.audio-item audio {
  width: 100%;
  margin-top: 1px;
}

/* Bouton de soumission avec la nouvelle couleur violet */
.modal-content-section .submit-button {
  background-color: #8762cc; /* Violet */
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

/* Couleur violet plus foncé au hover */
.modal-content-section .submit-button:hover {
  background-color: #6b4ea1;
}

@media (max-width: 768px) {
  .modal-title h2 {
    font-size: 1rem;
    text-align: center;
  }

  .modal-title p {
    font-size: 0.8rem; 
    text-align: center;
  }

  .audio-item p {
    font-size: 0.75rem;
  }

  .modal-header img {
    border-radius: 50%;
    height: 4rem;
    width: 4rem;
  }

  .audio-grid {
    display: block;
  }

  .modal-container {
    flex-direction: column;
    align-items: center;
  }

  .modal-image {
    padding-right: 0;
    margin-bottom: 20px;
  }

  .modal-content-section {
    padding-left: 0;
  }

  .modal-content-section h2 {
    text-align: center;
    font-size: 1.5rem;
  }

  .modal-content-section p {
    text-align: center;
    font-size: 1rem;
  }
}
/* Styles spécifiques à la Lead Magnet */
.leadmagnet-overlay-class {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.85); /* Arrière-plan sombre */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.leadmagnet-modal-content {
  background: #1e1e1e;
  padding: 20px;
  max-width: 600px;
  width: 90%;
  border-radius: 12px;
  outline: none;
  position: relative;
  z-index: 11;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.5);
}

/* Style du titre et du séparateur */
.leadmagnet-modal-title {
  color: #fff;
  font-size: 2rem;
  text-align: center;
  margin-bottom: 15px;
}

.leadmagnet-separator {
  border: 0;
  height: 1px;
  background-color: #8762cc;
  margin-bottom: 15px;
}

.leadmagnet-subtitle {
  color: #bbb;
  font-size: 1rem;
  margin-bottom: 20px;
  text-align: center;
}

/* Image du pop-up */
.leadmagnet-modal-image {
  text-align: center;
  margin-bottom: 20px;
}

/* Formulaire */
.leadmagnet-input {
  width: 100%;
  padding: 12px;
  margin-bottom: 15px;
  background-color: #333;
  border: 1px solid #555;
  border-radius: 8px;
  color: white;
  font-size: 1rem;
}

.leadmagnet-submit-button {
  width: 100%;
  background-color: #8762cc;
  color: white;
  padding: 12px;
  font-size: 1.25rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.leadmagnet-submit-button:hover {
  background-color: #6b4ea1;
}

/* Texte en bas */
.leadmagnet-bottom-text {
  text-align: center;
  color: #bbb;
  font-size: 0.875rem;
  margin-top: 20px;
}

/* Bouton de fermeture */
.leadmagnet-close-button {
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
}

@media (max-width: 768px) {
  .leadmagnet-modal-content {
    max-width: 95%;
  }

  .leadmagnet-modal-title {
    font-size: 1.5rem;
  }
}